import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { config } from './config';
import { initGA, setPageViewGA } from './utils/ga';
import { initMix } from './utils/mixpanel';
import Layout from './components/layout/Layout';
import Login from './pages/Login';
import Contact from './pages/Contact';
import Setting from './pages/setting';
import PaymentBenefit from './pages/paymentBenefit';
import PaymentDebit from './pages/paymentDebit';
import PaymentPremium from './pages/paymentPremium';
import PolicyLoan from './pages/policyLoan';
import Policy from './pages/policy';
import Claim from './pages/claim';
import ChangeContact from './pages/changeContact';
import HospitalNetwork from './pages/hospitalNetwork';
import Investment from './pages/investment';
import Documents from './pages/documents';
import SuggestProduct from './pages/suggestProduct';
import GroupEB from './pages/groupEB';
import NotFound from './pages/NotFound';
import Privilege from './pages/privilege';
import IndexLevelTableRoute from './pages/indexLevelTable';
import MaintenencePage from './pages/MaintenencePage';
import SearchPartner from './pages/searchPartner';
import TaxConsent from './pages/taxConsent';

import './style/main.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import MaintenenceHook from './components/layout/MaintenenceHook';
import CheckCMSNewVersionLayer from './components/layout/CheckCMSNewVersionLayer.js';

dayjs.locale('th');

if (process.env.NODE_ENV === 'production')
    console.log = function no_console() { };

const history = createBrowserHistory();

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: '',
        };
    }

    componentDidMount() {
        initGA(config.ga);
        initMix(config.mix);

        this.setState({ currentPage: window.location.pathname });

        history.listen(location => {
            setPageViewGA();

            setTimeout(() => {
                if (location.action === 'POP') {
                    return;
                }

                window.scrollTo(0, 0);
            });

            this.setState({ currentPage: location.pathname });
        });
    }

    render() {
        const { currentPage } = this.state;

        return (
            <Router history={history}>
                <MaintenenceHook>
                    <CheckCMSNewVersionLayer>
                        <Layout currentPage={currentPage} history={history}>
                            <Switch>
                                <Route exact path={`/`} component={NotFound} />
                                <Route path={`/login`} component={Login} />
                                <Route path={`/contact-us`} component={Contact} />
                                <Route path={`/setting`} component={Setting} />
                                <Route path={`/Mypolicy`} component={Policy} />
                                <Route path={`/eclaim`} component={Claim} />
                                <Route
                                    path={`/policy-loan`}
                                    component={PolicyLoan}
                                />
                                <Route
                                    path={`/payment-premium`}
                                    component={PaymentPremium}
                                />
                                <Route
                                    path={`/payout-benefit`}
                                    component={PaymentBenefit}
                                />
                                <Route
                                    path={`/payment-directdebit`}
                                    component={PaymentDebit}
                                />
                                <Route
                                    path={`/change-contact`}
                                    component={ChangeContact}
                                />
                                <Route
                                    path={`/hospital-network`}
                                    component={HospitalNetwork}
                                />
                                <Route
                                    path={`/investment`}
                                    component={Investment}
                                />
                                <Route
                                    path={`/suggest-product`}
                                    component={SuggestProduct}
                                />
                                <Route path={`/groupEB`} component={GroupEB} />
                                <Route path={`/privilege`} component={Privilege} />
                                <Route
                                    path={`/index-level-table`}
                                    component={IndexLevelTableRoute}
                                />
                                <Route
                                    path={`/maintenence`}
                                    component={MaintenencePage}
                                />
                                <Route
                                    path={`/search-partner`}
                                    component={SearchPartner}
                                />
                                <Route path={`/tax-consent`} component={TaxConsent} />

                                <Route
                                    path={`/documents-download`}
                                    component={Documents}
                                />

                                <Route component={NotFound} />
                            </Switch>
                        </Layout>
                    </CheckCMSNewVersionLayer>
                </MaintenenceHook>
            </Router>
        );
    }
}
