import { config } from '../config';
import { SERVICE_API } from './constants';
import { objectToQueryString } from '../utils/url'
import axios from 'axios'
const apiEndpoint = config.api_endpoint;
const apiEndpointFilenet = config.api_endpoint_filenet;
const apiEndpointEkyc = config.ekyc_endpoint.api;
const apiEndpointEkycToken = config.ekyc_endpoint.token;
const apiEndpointEkycPage = config.ekyc_endpoint.page;
const timeout = 50000;

export const REQUEST_POLICY = 'REQUEST_POLICY';
export const RECEIVE_POLICY = 'RECEIVE_POLICY';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const RECEIVE_UPDATE = 'RECEIVE_UPDATE';
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_USER_CHECK = 'REQUEST_USER_CHECK';
export const RECEIVE_USER_CHECK = 'RECEIVE_USER_CHECK';

export const requestPolicy = () => ({
    type: REQUEST_POLICY,
});

export const receivedPolicy = json => ({
    type: RECEIVE_POLICY,
    json,
});

export const requestUpdate = () => ({
    type: REQUEST_UPDATE,
});

export const receivedUpdate = json => ({
    type: RECEIVE_UPDATE,
    json,
});

export const requestUser = () => ({
    type: REQUEST_USER,
});

export const receivedUser = json => ({
    type: RECEIVE_USER,
    json,
});

export const requestUserCheck = () => ({
    type: REQUEST_USER_CHECK,
});

export const receivedUserCheck = json => ({
    type: RECEIVE_USER_CHECK,
    json,
});

// Wraper promise with timeout
const promiseWithTimeout = (promise, ms = 7000) =>
    new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error('Promise timeout!'));
        }, ms);
        promise.then(
            res => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            err => {
                clearTimeout(timeoutId);
                reject(err);
            },
        );
    });

// Generate http request options
const getOptions = (method, body) => ({
    method,
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
        // 'CSRF-Token': body._csrf,
        // Authorization: `Basic ${btoa(
        //     `${config.auth.user}:${config.auth.pass}`,
        // )}`,
    },
    // credentials: 'include',
    body: JSON.stringify(body),
});

// |--------------------------------------------|
// |---------------- HTTP Method ---------------|
// |--------------------------------------------|

export const rawHttpGet = (url, options) => {
    const promise = fetch(url, options || {});

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const httpGet = path => {
    const promise = fetch(`${apiEndpoint}${path}`);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const httpPost = (path, data) => {
    const options = getOptions('POST', data);
    const promise = fetch(`${apiEndpoint}${path}`, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};


export const httpPostMod = (path, data) => {
    const options = getOptions('POST', data);
    const promise = fetch(`http://localhost:3002${path}`, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const httpPostUpload = (path, formData) => {
    const options = {
        method: 'POST',
        body: formData,
    };
    const promise = fetch(`${apiEndpointFilenet}${path}`, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

// |--------------------------------------------|
// |------------------ FileNet -----------------|
// |--------------------------------------------|

export const getFilenetDetail = async (policyNo, productType) => {
    try {
        const url = `${apiEndpointFilenet}/file/browse?reference1=${productType}&reference2=${policyNo}`;

        const result = await rawHttpGet(url);

        return result;
    } catch (error) {
        return error;
    }
};

export const getFilenetUrl = id =>
    `${apiEndpointFilenet}/file/pdf?doc_id=${id}`;

// |--------------------------------------------|
// |------------------- eKYC -------------------|
// |--------------------------------------------|

export const checkEkyc = async idCard => {
    try {
        const result = await rawHttpGet(`${apiEndpointEkyc}/customer?id_number=${idCard}`, {
            headers: { Authorization: `Bearer ${apiEndpointEkycToken}` },
        });

        return result;
    } catch (error) {
        return error;
    }
};

export const getEkycPage = (idCard, system, callback) =>
    `${apiEndpointEkycPage}?id_number=${idCard}&system=${encodeURI(
        system,
    )}&callback=${callback}`;

// |--------------------------------------------|
// |------------------ Policy ------------------|
// |--------------------------------------------|

export const getPolicy = id => {
    return dispatch => {
        dispatch(requestPolicy());

        const promise = fetch(`${apiEndpoint}/api/policy-detail/${id}`);

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedPolicy(json)))
            .catch(error => dispatch(receivedPolicy({ error })));
    };
};

export const updateCustomer = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-customer-name/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const updateContact = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-customer-contact/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const updateAddress = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-customer-address/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const updateBeneficiary = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-beneficiary/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const reprintPolicy = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-reprint/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const requestCancelPolicy = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-request-cancel/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const cancelPolicy = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const options = getOptions('POST', body);
        const promise = fetch(
            `${apiEndpoint}/api/policy-cancel/${id}`,
            options,
        );

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const clearUpdate = () => dispatch => dispatch(receivedUpdate({}));

// |--------------------------------------------|
// |----------------- Settings -----------------|
// |--------------------------------------------|

export const getPruPolicyList = id => {
    return dispatch => {
        dispatch(requestPolicy());

        const url = `${apiEndpoint}${SERVICE_API.PRUPOLICY_LIST}`.replace(
            /:id/,
            id,
        );
        const promise = fetch(url);

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedPolicy(json)))
            .catch(error => dispatch(receivedPolicy({ error })));
    };
};

export const getPolicyList = id => {
    const url = `${apiEndpoint}${SERVICE_API.PRUPOLICY_LIST}`.replace(
        /:id/,
        id,
    );
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyLists = (id, page, productType) => {
    const url = `${apiEndpoint}${SERVICE_API.MY_POLICY_LIST}?page=${page}&product_type=${productType}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPrumiumPaymentPolicies = id => {
    const url = `${apiEndpoint}${SERVICE_API.PREMIUM_PAYMENT_GET_POLICIES}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimPolicyLists = id => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_POLICY_LIST}`
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyDetail = (id, policyCertNo) => {
    const url = `${apiEndpoint}${SERVICE_API.POLICY_DETAIL}`.replace(
        /:policyCertNo/,
        policyCertNo,
    );
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyLoanPagingList = (id, page, status) => {
    const url = `${apiEndpoint}${SERVICE_API.LOAN_POLICY_LIST_PAGING}?page=${page}&status=${status}`;
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyListDirectdebit = id => {
    const url = `${apiEndpoint}${SERVICE_API.DIRECT_DEBIT_POLICY_LIST}`
    const promise = fetch(url, {
        headers: {
            authorization: id,
        }
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyListBenefit = id => {
    const url = `${apiEndpoint}${SERVICE_API.BENEFIT_PAYOUT_POLICY_LIST}`
    const promise = fetch(url, {
        headers: {
            authorization: id,
        }
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getAccountMapping = id => {
    const url =
        `${apiEndpoint}/account-mapping`
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getBankAccountByPolicyNo = (id, policyNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.MY_POLICY_GET_BANK_ACCOUNT}`.replace(
            /:policyNo/,
            policyNo,
        );
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolciyWithCertNo = (id, certNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.PREMIUM_PAYMENT_GET_POLICIES_CERT_NO}`.replace(
            /:certNo/,
            certNo,
        );
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};
export const submitPaymentCheckout = (authToken, certNo, body) => {
    const url = `${apiEndpoint}${SERVICE_API.SUBMIT_PAYMENT_CEHCKOUT}`.replace(
        /:certNo/,
        certNo,
    );
    const promise = fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: authToken,
        },
        body: JSON.stringify(body),
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getSearchAgentList = (id, body) => {
    const url = `${apiEndpoint}${SERVICE_API.SEARCH_AGENT_LIST}`;
    const promise = fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: id,
        },
        body: JSON.stringify(body),
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getBrokerList = id => {
    const url = `${apiEndpoint}${SERVICE_API.BROKER_LIST}`;
    const promise = fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: id,
        },
        // body: JSON.stringify(body),
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const submitTtbTheTeasure = (authToken, policyNo, formData) => {
    const url =
        `${apiEndpoint}${SERVICE_API.MY_POLICY_SUBMIT_TTB_THE_TEASURE}`.replace(
            /:policyNo/,
            policyNo,
        );
    const promise = fetch(url, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            authorization: authToken,
        },
        body: formData,
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPaymentStatus = orderRef => {
    const url = `${apiEndpoint}${SERVICE_API.GET_PAYMENT_STATUS}`.replace(
        /:orderRef/,
        orderRef,
    );
    const promise = fetch(url, {
        method: 'GET',
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getCostType = () => {
    const url = `${apiEndpoint}${SERVICE_API.MASTER_DATA_GET_COST_TYPE}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getBankListData = () => {
    const url = `${apiEndpoint}${SERVICE_API.MASTER_DATA_GET_BANK}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .then(result => {
            if (result.data.length > 0) {
                result.data.sort((a, b) =>
                    a.bankNameTh.localeCompare(b.bankNameTh, 'th', {
                        sensitivity: 'base',
                    }),
                );
            }
            return result;
        })
        .catch(error => error);
};

export const getRelationship = () => {
    const url = `${apiEndpoint}${SERVICE_API.MASTER_DATA_GET_RELATIONSHIP}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimCheckAccountMapping = id => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_CHECK_ACCOUNT_MAPPING}`
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimGroupMemberInsures = id => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_GROUP_MEMBER}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

// export const getMe = id => {
//     const url = `${apiEndpoint}${SERVICE_API.PRUPOLICY_ME}`.replace(/:id/, id);
//     const promise = fetch(url);

//     return promiseWithTimeout(promise, timeout)
//         .then(response => response.json())
//         .catch(error => error);
// };

export const getUserSetting = id => {
    return dispatch => {
        dispatch(requestUser());

        const url =
            `${apiEndpoint}${SERVICE_API.USER_SETTING_PREMIUM_REMINDER}`
        const promise = fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: id,
            },
            // body: JSON.stringify(body),
        });

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUser(json)))
            .catch(error => dispatch(receivedUser({ error })));
    };
};

export const updateUserSetting = (id, body) => {
    return dispatch => {
        dispatch(requestUpdate());

        const url =
            `${apiEndpoint}${SERVICE_API.USER_SETTING_PREMIUM_REMINDER}`
        const promise = fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                authorization: id,
            },
            body: JSON.stringify(body),
        });

        return promiseWithTimeout(promise, timeout)
            .then(response => response.json())
            .then(json => dispatch(receivedUpdate(json)))
            .catch(error => dispatch(receivedUpdate({ error })));
    };
};

export const getPremiumDetailBFF = (id, policyNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.BFF_PREMIUM_DETAIL}?policyNo=${policyNo}`.replace(
            /:id/,
            id,
        );
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getCoverageDetailBFF = (id, policyNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.BFF_COVERAGE_DETAIL}?policyNo=${policyNo}`.replace(
            /:id/,
            id,
        );
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getEmedicalCardDetailBFF = (id, policyNo) => {
    const url = `${apiEndpoint}${SERVICE_API.BFF_EMEDICAL_DETAIL}`.replace(
        /:policyNo/,
        policyNo,
    );
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => {
            return response.json();
        })
        .catch(error => error);
};

export const getPolicyDetailBFF = (id, policyNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.BFF_POLICY_DETAIL}?policyNo=${policyNo}`.replace(
            /:id/,
            id,
        );
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const checkClaimMaintenance = () => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_CHECK_MAINTENANCE}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimLists = (id) => {
    const url =
        `${apiEndpoint}${SERVICE_API.CLAIM_LIST}`
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimReason = id => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_REASON}`
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimBankList = id => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_BANK_LIST}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimBankAccount = (id) => {
    const url =
        `${apiEndpoint}${SERVICE_API.CLAIM_BANK_ACCOUNT}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimICD10nHospital = (id, type) => {
    const url =
        `${apiEndpoint}${SERVICE_API.CLAIM_HOSPITAL}?type=${type}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const checkDuplicateClaim = async (id, body) => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_DUPLICATE}`;
    const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            authorization: id,
            'Content-type': 'application/json; charset=UTF-8',
        },
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const uploadDocumentsClaim = async (lineUid, formData) => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_UPLOAD_DOCUMENT}`;
    const options = {
        method: 'POST',
        body: formData,
        headers: {
            authorization: lineUid,
        },
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const submitClaim = async (id, formData) => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_SUBMIT}`;
    const promise = fetch(url, {
        method: 'POST',
        headers: {
            authorization: id,
        },
        body: formData,
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getTier = (id, campaign, campaignId) => {
    const url = `${apiEndpoint}${SERVICE_API.PRIVILEGE_GET_TIER}`

    let queryStr = `${url}?campaign=${campaign}&campaignId=${campaignId}`;

    const promise = fetch(queryStr, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getPolicyListContact = id => {
    const url = `${apiEndpoint}${SERVICE_API.BFF_CHANGE_CONTACT}`;

    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const searchAddress = (id, searchText) => {
    const url = '/mock-address.json'; //`${apiEndpoint}${SERVICE_API.SEARCH_ADDRESS}`;

    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const checkValidation = (line_uid, payload) => {
    const url = `${apiEndpoint}${SERVICE_API.BFF_CHANGE_CONTACT_VALIDATION}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: line_uid,
        },
        body: JSON.stringify(payload),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};
export const submitChangeContact = (line_uid, payload) => {
    const url = `${apiEndpoint}${SERVICE_API.BFF_CHANGE_CONTACT_SUBMIT}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: line_uid,
        },
        body: JSON.stringify(payload),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getIndexPriceLevel = params => {
    let queryString = objectToQueryString(params);
    const url = `${apiEndpoint}${SERVICE_API.BFF_INDEX_PRICE_LEVEL}?${queryString}`;

    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getMaintenencePCFlag = () => {
    const url = `${apiEndpoint}${SERVICE_API.MAINTENENCE_PC_FLAG}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getMaintenenceCMSFlag = () => {
    const url = `${apiEndpoint}${SERVICE_API.MAINTENENCE_CMS_FLAG}`;
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const submitLoan = async (id, body) => {
    const url = `${apiEndpoint}${SERVICE_API.LOAN_SUBMIT}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: id,
        },
        body: JSON.stringify(body),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const cancelPolicyLoan = (line_uid, params) => {
    const { policyNo, otpNo, otpRef } = params
    const url = `${apiEndpoint}${SERVICE_API.LOAN_CANCEL}`.replace(
        /:policyNo/,
        policyNo,
    );
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: line_uid,
        },
        body: JSON.stringify({ otpNo, otpRef }),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const rollbackPolicyLoan = async (line_uid, policyNo) => {
    const url = `${apiEndpoint}${SERVICE_API.LOAN_ROLLBACK}`.replace(
        /:policyNo/,
        policyNo,
    );
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: line_uid,
        },
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getTaxConsentPolicies = id => {
    const url = `${apiEndpoint}${SERVICE_API.TAX_CONSENT_POLICIES}`;
    let options = {
        method: 'GET',
        headers: {
            authorization: id,
        },
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const submitConsent = (id, data) => {
    const url = `${apiEndpoint}${SERVICE_API.TAX_CONSENT_UPDATE_CONSENT}`;
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: id,
        },
        method: 'POST',
        body: JSON.stringify(data),


    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getFundDetail = (id, policyNo) => {
    const url =
        `${apiEndpoint}${SERVICE_API.MY_POLICY_GET_FUND_DETAIL}`.replace(
            /:policyNo/,
            policyNo,
        );
    const promise = fetch(url, {
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getFundDetailByPolicyNo = (id, policyNo, fundCode, coverageNo) => {
    const url = `${apiEndpoint}${SERVICE_API.MY_POLICY_GET_FUND_DETAIL_BY_POLICY}`.replace(
        /:policyNo/,
        policyNo,
    )
    let queryStr = `${url}?fund_code=${fundCode}&coverage_no=${coverageNo}`;

    const promise = fetch(queryStr, {
        method: 'GET',
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};


export const getUserConsents = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.USER_CONSENT}`

    const promise = fetch(url, {
        method: 'GET',
        headers: {
            authorization: id,
        },
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const updateUserConsents = (id, data) => {
    const url = `${apiEndpoint}${SERVICE_API.USER_CONSENT}`

    const promise = fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: id,
        },
        body: JSON.stringify(data),
    });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const updatePaymentBenefitSetting = async (id, body) => {
    const url = `${apiEndpoint}${SERVICE_API.BENEFIT_PAYOUT_UPDATE_PAYMENT_BENEFIT_SETTING}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: id,
        },
        body: JSON.stringify(body),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const updatePaymentCreditSetting = async (id, body) => {
    const url = `${apiEndpoint}${SERVICE_API.DIRECT_DEBIT_UPDATE_PAYMENT_CREDIT_SETTING}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: id,
        },
        body: JSON.stringify(body),
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const logout = async (id) => {
    const url = `${apiEndpoint}${SERVICE_API.USER_LOGOUT}`;
    var options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: id,
        },
    };

    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
}

export const getListFundDetail = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.LIST_FUND_DETAIL}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getListFundStatement = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.LIST_FUND_STATEMENT}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getListParAnnualStatment = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.LIST_PAR_ANNUAL_STATEMENT}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getListTaxCertificate = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.LIST_TAX_CERTIFICATE}`;
    const promise = fetch(url, { headers: { authorization: id } });

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const downloadFundDetailFile = (policyNo, date) => {
    const filename = `${policyNo}_Fund detail_${date}`;
    const url = `${apiEndpoint}${SERVICE_API.DOWNLOAD_FUND_DETAIL_FILE}/${filename}?policyNo=${policyNo}`;
    return window.open(url, '_blank', 'noopener,noreferrer');
};

export const downloadFundStatementFile = (base64Data, policyNo, date) => {
    const filename = `${policyNo}_Fund statement_${date}`;
    const url = `${apiEndpoint}${SERVICE_API.DOWNLOAD_FUND_STATEMENT_FILE}/${filename}?data=${base64Data}`
    return window.open(url, '_blank', 'noopener,noreferrer');
}

export const downloadParAnnualStatementFile = (filedId, year) => {
    const filename = `Annual statement_${year}`;
    const url = `${apiEndpoint}${SERVICE_API.DOWNLOAD_PAR_ANNUAL_STATEMENT_FILE}/${filename}?fileId=${filedId}`
    return window.open(url, '_blank', 'noopener,noreferrer');
}

export const downloadTaxCertificateFile = (masterPolicy, policyNo, year) => {
    const filename = `${masterPolicy ? `${masterPolicy}-` : ''}${policyNo}_Premium Certificate_${year}`;
    const urlHaveMasterPolicy = `${apiEndpoint}${SERVICE_API.DOWNLOAD_TAX_CERTIFICATE_FILE}/${filename}?masterPolicy=${masterPolicy}&policyNo=${policyNo}&taxYear=${year}&`
    const url = `${apiEndpoint}${SERVICE_API.DOWNLOAD_TAX_CERTIFICATE_FILE}/${filename}?policyNo=${policyNo}&taxYear=${year}`
    return window.open(masterPolicy ? urlHaveMasterPolicy : url, '_blank', 'noopener,noreferrer');
}

export const getClaimOcrMedReceipt = (id, formData) => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_MED_OCR_RECEIPT}`;

    var options = {
        method: 'POST',
        headers: {
            Authorization: id,
        },
        body: formData,
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getOcrAcceptableRateListData = (id) => {
    const url = `${apiEndpoint}${SERVICE_API.CLAIM_OCR_ACCEPTRATE}`;

    // var options = {
    //     method: 'get',
    //     // headers: {
    //     //     Authorization: id,
    //     // },
    //     // body: formData,
    // };
    const promise = fetch(url);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimOcrMedCertificate = (id, formData) => {
    const url =
        `${apiEndpoint}${SERVICE_API.CLAIM_MED_OCR_CERTIFICATE}`;

    var options = {
        method: 'POST',
        headers: {
            Authorization: id,
        },
        body: formData,
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};

export const getClaimOcrBookBank = (id, formData) => {
    const url =
        `${apiEndpoint}${SERVICE_API.CLAIM_OCR_BOOKBANK}`;

    var options = {
        method: 'POST',
        headers: {
            Authorization: id,
        },
        body: formData,
    };
    const promise = fetch(url, options);

    return promiseWithTimeout(promise, timeout)
        .then(response => response.json())
        .catch(error => error);
};