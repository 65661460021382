import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const DocumentList = Loadable({
    loader: () => import('./documentList'),
    loading: () => null,
});

const DocumentDetail = Loadable({
    loader: () => import('./documentDetail'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function DocumentRoute() {
    return (
        <Switch>
            <Route exact path={`/documents-download`} component={DocumentList} />
            <Route
                path={`/documents-download/detail`}
                component={DocumentDetail}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
