import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../utils/analytics';
import { getParameterByName } from '../../utils/url';
import { config } from './../../config';

const backPage = {
    '/Mypolicy/Mypolicy-detail': '/Mypolicy',
    '/payment-premium/form': '/payment-premium',
    '/eclaim/form': '/eclaim/mainpage',
    '/eclaim/tc': '/eclaim/form',
    '/payout-benefit/form': '/payout-benefit',
    '/payout-benefit/summary': '/payout-benefit/form',
    '/payout-benefit/otp': '/payout-benefit/summary',
    '/policy-loan/policy-pending': '/policy-loan',
    '/policy-loan/tooltip-information': '/policy-loan',
    '/policy-loan/consent-TC': '/policy-loan',
    '/policy-loan/fillin-form': '/policy-loan/consent-TC',
    '/policy-loan/summary': '/policy-loan/fillin-form',
    '/policy-loan/confirmation-otp': '/policy-loan/summary',
    '/policy-loan/cancel-otp': '/policy-loan/policy-pending',
    '/payment-directdebit/select-method': '/payment-directdebit',
    '/payment-directdebit/bank-account': '/payment-directdebit/select-method',
    '/payment-directdebit/credit-card-tc': '/payment-directdebit/select-method',
    '/payment-directdebit/credit-card-form':
        '/payment-directdebit/credit-card-tc',
    '/payment-directdebit/credit-card-otp':
        '/payment-directdebit/credit-card-form',
    '/setting/consent': '/setting',
    '/setting/consent-otp': '/setting/consent',
    '/d/Mypolicy/Mypolicy-detail': '/d/Mypolicy',
    '/d/payment-premium/form': '/d/payment-premium',
    '/d/payout-benefit/form': '/d/payout-benefit',
    '/d/payout-benefit/summary': '/d/payout-benefit/form',
    '/d/payout-benefit/otp': '/d/payout-benefit/summary',
    '/d/policy-loan/policy-pending': '/d/policy-loan',
    '/d/policy-loan/tooltip-information': '/d/policy-loan',
    '/d/policy-loan/consent-TC': '/d/policy-loan',
    '/d/policy-loan/fillin-form': '/d/policy-loan/consent-TC',
    '/d/policy-loan/summary': '/d/policy-loan/fillin-form',
    '/d/policy-loan/confirmation-otp': '/d/policy-loan/summary',
    '/d/policy-loan/cancel-otp': '/d/policy-loan/policy-pending',
    '/d/payment-directdebit/select-method': '/d/payment-directdebit',
    '/d/payment-directdebit/bank-account':
        '/d/payment-directdebit/select-method',
    '/d/payment-directdebit/credit-card-tc':
        '/d/payment-directdebit/select-method',
    '/d/payment-directdebit/credit-card-form':
        '/d/payment-directdebit/credit-card-tc',
    '/d/payment-directdebit/credit-card-otp':
        '/d/payment-directdebit/credit-card-form',

    '/d/setting/consent': '/d/setting',
    '/d/setting/consent-otp': '/d/setting/consent',
    '/documents-download/detail': '/documents-download',
};

const pageMultiLanguage = [
    '/setting',
    '/setting/consent',
    '/setting/consent-otp',
    '/setting/consent-complete',
    '/eclaim',
    '/eclaim/mainpage',
    '/eclaim/form',
    '/eclaim/tc',
    '/eclaim/complete',
    '/eclaim/reject',
    '/search-partner/agent',
    '/search-partner/broker',
];

function Header(props) {
    const { i18n } = useTranslation();
    const [previousPage, setPreviousPage] = useState();

    useEffect(() => {
        const tempBackPage = { ...backPage };

        if (props.currentPage.includes('/Mypolicy/Mypolicy-detail')) {
            const currentUrl = window.location.href;

            const id = getParameterByName(currentUrl, 'id');

            if (!id) {
                delete tempBackPage['/Mypolicy/Mypolicy-detail'];
                delete tempBackPage['/d/Mypolicy/Mypolicy-detail'];
            }
        }

        setPreviousPage(tempBackPage[props.currentPage]);
    });

    return (
        <>
            <header id="header-main">
                <span>
                    {previousPage && (
                        <img
                            onClick={() => props.history.push(previousPage)}
                            src="/assets/icons/arrow-left.svg"
                            alt="back"
                        />
                    )}
                </span>
                <img
                    src={`${config.blobUrl}/pruconnect/pruconnect_logo.webp`}
                    alt="Logo Prudential.co.th"
                />
                {pageMultiLanguage.includes(props.currentPage) ? (
                    <span
                        onClick={() => {
                            trackEvent(
                                'PRUConnect',
                                `Change Language: ${
                                    i18n.language === 'th' ? 'EN' : 'TH'
                                }`,
                            );

                            i18n.changeLanguage(
                                i18n.language === 'th' ? 'en' : 'th',
                            );
                        }}
                    >
                        {i18n.language === 'th' ? 'EN' : 'TH'}
                    </span>
                ) : (
                    <span></span>
                )}
            </header>
        </>
    );
}

export default Header;
