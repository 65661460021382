import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState, userLineState, maintenenceGlobalState } from '../../state';
import Loading from '../../components/loading';
import liff from '@line/liff';
import { trackUser, trackPageView, trackEvent } from '../../utils/analytics';
import { config } from '../../config';
import { getAccountMapping } from '../../actions';
import { getParameterByName } from '../../utils/url';

const liffId = config.liff_id.investment;
const liffUrl = `https://liff.line.me/${liffId}`;

function Investment(props) {
    const params = new URLSearchParams(props.location.search);
    const userId = params.get('id');
    const [user, setUser] = useRecoilState(userState);
    // // const [userLine, setUserLine] = useRecoilState(userLineState);
    const liffState = decodeURIComponent(params.get('liff.state'));
    const source = liffState ? getParameterByName(liffState, 'source') : null;
    const [profile, setProfile] = useState(userId ? { userId } : {});
    const [userLine, setUserLine] = useRecoilState(userLineState);
    const [maintenenceGlobal] = useRecoilState(
        maintenenceGlobalState,
    );
    useEffect(() => {
        const initialize = async () => {
            if (userId) {
                setUserLine(profile);
                _getAccount(userId);
            } else {
                if (liff.isInClient()) {
                    try {
                        await liff.init({ liffId });
                        const profile = await liff.getProfile();
                        setUserLine(profile);
                        setProfile(profile);

                        await _getAccount(profile.userId);
                    } catch (error) {
                        if (profile?.userId) {
                            setUserLine(profile);
                            await _getAccount(profile.userId);
                        } else {
                            // props.history.push(`/not-found`);
                        }
                    }
                } else {
                    setUserLine(profile);
                    await _getAccount(profile.userId);
                }
            }
            window.location.href = config.pruserviceUrl;
            return null;
        };
        if (!userLine?.userId) {
            if (!maintenenceGlobal?.isMaintenance) {
                initialize();
            }
        }
    }, []);

    const _getAccount = async _userId => {
        if (!user?.username) {
            const resultUser = await getAccountMapping(_userId);
            if (resultUser?.data && resultUser?.status === 'OK') {
                trackUser(resultUser.data);
                setUser(resultUser.data);
            } else {
                return (window.location.href = `${config.pruid_login}&c=${liffUrl}`);
            }
        }

        trackPageView(source);
        trackEvent('UL Service link to PRUServices', 'UL Service link to PRUServices');
    };

    return <Loading />;
}

export default Investment;
